import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ProjectCard from "../../components/ProjectCard/ProjectCard";

// Importation du fichier JSON
import projectsData from "../../utils/data/projects.json"; 

const Container = styled.div`
    width: 100%;
    margin-top: 3%;
    margin-left: 250px;

    @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 40%;
        margin-left: 0;
    }
`;

const VerticalButton = styled.button`
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.colors.secondary};
    border: none;
    color: ${(props) => props.theme.colors.primary};
    font-size: 30px;
    font-weight: 800;
    text-transform: uppercase;
    cursor: pointer;
    padding: 20px;

    @media (max-width: 768px) {
        top: 10px; 
        right: 10px; 
        height: auto;
        flex-direction: row;
        font-size: 18px;
        gap: 10px;
        padding: 10px 20px; 
`;

const Letter = styled.span`
    display: block;
    justify-content: center;
`;

const Word = styled.div`
    margin-bottom: 30px;

    @media (max-width: 768px) {
        margin-bottom: 0; 
    }
`;

const SliderProject = () => {
    const firstWord = "Projet";
    const secondWord = "Suivant";

    // Utilisation du state pour stocker l'index du projet actuel
    const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

    // Charger les données des projets depuis le fichier JSON
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        // Simuler le chargement des données depuis le fichier JSON
        setProjects(projectsData);
    }, []);

    // Fonction pour passer au projet suivant
    const handleNextProject = () => {
        setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % projects.length);
    };

    // Récupérer le projet actuel
    const currentProject = projects[currentProjectIndex];

    const firstLetters = firstWord.split('').map((letter, index) => (
        <Letter key={`first-${index}`}>{letter}</Letter>
    ));

    const secondLetters = secondWord.split('').map((letter, index) => (
        <Letter key={`second-${index}`}>{letter}</Letter>
    ));

    return (
        <Container>
            {/* Afficher la carte du projet si les projets sont chargés */}
            {currentProject && (
                <ProjectCard
                    title={currentProject.title}
                    subtitle={currentProject.subtitle}
                    image={currentProject.image}
                    paragraphe1={currentProject.paragraphe1}
                    paragraphe2={currentProject.paragraphe2}
                    paragraphe3={currentProject.paragraphe3}
                    stack={currentProject.stack}
                    detailsLink={currentProject.detailsLink}
                    projectsLink={currentProject.projectsLink}
                />
            )}

            {/* Bouton "Projet Suivant" */}
            <VerticalButton onClick={handleNextProject}>
                <Word>{firstLetters}</Word>
                <Word>{secondLetters}</Word>
                <div style={{ display: 'none' }}>
                    {firstWord} {secondWord}
                </div>
            </VerticalButton>
        </Container>
    );
};

export default SliderProject;
