import styled from 'styled-components';
import StackUsed from '../StackUsed/StackUsed';
import CallAction from '../Button/CallAction';

const Card = styled.div`
    display: flex;
    gap: 50px;

    @media (max-width: 768px) {
        justify-content: start;
        gap: 0;
        margin-left: 8%;
    }
`;

const Title = styled.h1`
    font-size: 52px;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 32px;
        margin-left: 0; 
        margin-top: 5%;
        padding-right: 0; 
    }
`;

const Mea = styled.span`
    font-family: ${(props) => props.theme.fonts.code};
    font-weight: 400;
    font-size: 68px;
    margin-left: 40%;

    @media (max-width: 768px) {
        font-size: 32px;
        margin-left: 45%;
    }
`;

const Image = styled.img`
    margin-top: -4%;
    margin-left: 8%;
    width: 600px;
    height: 350px;
    object-fit: cover;

    @media (max-width: 768px) {
        display: none;
    }

    @media (min-width: 1800px) {
        width: 800px;
        height: 550px;
    }
`;

const BtnDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;

    @media (max-width: 768px) {
        gap: 10px;
        width: 90%;
    }
`;

const Pres = styled.div`
    margin-left: 8%;
    margin-top: 8%;
    display: flex;
    justify-content: center;
    gap: 100px;
    width: 80%;

    @media (max-width: 768px) {
        gap: 20px;
        width: 90%;
        flex-direction: column-reverse;
    }

    @media (min-width: 1800px) {
            margin-top: 5%;
    }
`;

const Para = styled.div`
    width: 70%;
    p {
        margin-bottom: 3%;
    }

    @media (max-width: 768px) {
        width: 90%;
    }
`;

const ProjectCard = ({ title, subtitle, image, paragraphe1, paragraphe2, paragraphe3, stack, detailsLink, projectsLink }) => {
    return (
        <div>
            <StackUsed images={stack} />
            <Card>
                <Image src={image} alt="image projet" />
                <Title>
                    {title} <Mea>{subtitle}</Mea>
                </Title>
            </Card>
            <Pres>
                <BtnDiv>
                    {/* <CallAction to={detailsLink} textButton="Détails du projet" /> */}
                    <CallAction to={projectsLink} textButton="Visiter le site Web" />
                </BtnDiv>
                <Para>
                    <p>{paragraphe1}</p>
                    <p>{paragraphe2}</p>
                    <p>{paragraphe3}</p>
                </Para>
            </Pres>
        </div>
    );
};

export default ProjectCard;
