import { useState } from 'react'
import styled from 'styled-components'
import emailjs from 'emailjs-com'

const FormContainer = styled.div`
     display: inline-block;
     background-color: ${(props) => props.theme.colors.primary};
     color: ${(props) => props.theme.colors.secondary};
     padding: ${(props) => props.theme.spacing.large} 50px;

     @media (max-width: 768px) {
          width: 100%;
          padding: ${(props) => props.theme.spacing.large};
     }
`;

const FormTitle = styled.h3`
     font-weight: black;
     font-size: 22px;
     margin-bottom: ${(props) => props.theme.spacing.medium};

     @media (max-width: 768px) {
          font-size: 18px;
     }

     @media (min-width: 1800px) {
          font-size: 26px;
     }
`;

const NameGroup = styled.div`
     display: flex;
     gap: 100px;
     margin-bottom: ${(props) => props.theme.spacing.medium};

     @media (max-width: 768px) {
          gap: ${(props) => props.theme.spacing.medium};
          flex-direction: column;
     }
     
     @media (min-width: 1800px) {
          gap: 50px;
     }
`;

const FormGroup = styled.div`
     margin-bottom: ${(props) => props.theme.spacing.medium};

     @media (min-width: 1800px) {
          margin-bottom: ${(props) => props.theme.spacing.large};
     }
`;

const Input = styled.input`
     width: 100%;
     padding: 15px;
     background-color: ${(props) => props.theme.colors.secondary};
     border: none;
     color: ${(props) => props.theme.colors.primary};
     font-weight: 400;

     &:focus {
          border-color: ${(props) => props.theme.colors.primary};
          outline: none;
     }

     &::placeholder {
          color: ${(props) => props.theme.colors.primary};
          font-weight: 600;
     }

     @media (max-width: 768px) {
          font-size: 14px;
     }

     @media (min-width: 1800px) {
          font-size: 18px;
     }
`;

const Textarea = styled.textarea`
     width: 100%;
     padding: 15px;
     background-color: ${(props) => props.theme.colors.secondary};
     border: none;
     resize: none;
     color: ${(props) => props.theme.colors.primary};
     font-weight: 400;

     &:focus {
          border-color: ${(props) => props.theme.colors.primary};
          outline: none;
     }

     &::placeholder {
          color: ${(props) => props.theme.colors.primary};
          font-weight: 600;
     }

     @media (max-width: 768px) {
          font-size: 14px;
     }

     @media (min-width: 1800px) {
          font-size: 18px;
     }
`;

const ButtonStyle = styled.button`
     background-color: ${(props) => props.theme.colors.secondary};
     color: ${(props) => props.theme.colors.primary};
     padding: ${(props) => props.theme.spacing.small} ${(props) => props.theme.spacing.medium};
     border: none;
     font-weight: bold;
     font-size: 16px;
     text-transform: uppercase;
     margin-bottom: ${(props) => props.theme.spacing.small};

     &:hover {
          background-color: ${(props) => props.theme.colors.primary};
          border: ${(props) => props.theme.colors.secondary} 2px solid;
          color: ${(props) => props.theme.colors.secondary};
     }
`;

const RgpdCheckbox = styled.div`
     display: flex;
     align-items: center;
     margin-bottom: ${(props) => props.theme.spacing.medium};

     input {
     margin-right: ${(props) => props.theme.spacing.small};
     }

     label {
     font-size: 14px;
     }

     @media (min-width: 1800px) {
          label {
               font-size: 16px;
          }
     }
`;

const ContactForm = () => {
     const [formData, setFormData] = useState({
          lastName: '',
          firstName: '',
          email: '',
          message: '',
     });
     const [isRgpdChecked, setIsRgpdChecked] = useState(false);

     const handleChange = (e) => {
          const { name, value } = e.target;
          setFormData({ ...formData, [name]: value });
     };

     const handleCheckboxChange = (e) => {
          setIsRgpdChecked(e.target.checked);
     };

     // Validation des inputs
     const validateForm = () => {
          // Vérification des champs nom et prénom (2 à 30 caractères)
          if (formData.lastName.length < 2 || formData.lastName.length > 30) {
               alert("Le nom doit contenir entre 2 et 30 lettres.");
               return false;
          }
          if (formData.firstName.length < 2 || formData.firstName.length > 30) {
               alert("Le prénom doit contenir entre 2 et 30 lettres.");
               return false;
          }
          // Vérification de l'email
          const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if (!emailPattern.test(formData.email)) {
               alert("Veuillez entrer une adresse email valide.");
               return false;
          }
          // Vérification du message (25 à 300 caractères)
          if (formData.message.length < 25 || formData.message.length > 300) {
               alert("Le message doit contenir entre 25 et 300 caractères.");
               return false;
          }

          if (!isRgpdChecked) {
               alert("Veuillez accepter les conditions RGPD.");
               return false;
          }

          return true;
     }

     const handleSubmit = (e) => {
          e.preventDefault()
          if (!validateForm()) return;
          const templateParams = {
               to_name: 'Bordier Kimberley',
               lastName: formData.lastName,
               firstName: formData.firstName,
               email: formData.email,
               message: formData.message,
          };

          emailjs.send('service_portfolio', 'template_c879hf4', templateParams, 'bALzn8kPebFdj5FlR')
               .then((response) => {
                    console.log('Email envoyé avec succès !', response.status, response.text);
                    // Afficher un message de succès à l'utilisateur
                    alert('Votre message a été envoyé avec succès !');
               })
               .catch((err) => {
                    console.error('Erreur lors de l\'envoi de l\'email:', err);
                    // Afficher un message d'erreur à l'utilisateur
                    alert(`Une erreur est survenue, veuillez réessayer. Erreur: ${err.text}`);
               });

          // Réinitialiser le formulaire après soumission
          setFormData({ lastName: '', firstName: '', email: '', message: '' });
          setIsRgpdChecked(false); // Réinitialise la case RGPD
     };

     return (
          <FormContainer>
               <FormTitle>N'hésitez pas à me contacter.</FormTitle>
               <form onSubmit={handleSubmit}>
                    <NameGroup>
                         <Input
                              type="text"
                              id="lastName"
                              name="lastName"
                              value={formData.lastName}
                              placeholder='Nom'
                              onChange={handleChange}
                              required
                         />
                         <Input
                              type="text"
                              id="firstName"
                              name="firstName"
                              value={formData.firstName}
                              placeholder='Prénom'
                              onChange={handleChange}
                              required
                         />
                    </NameGroup>
                    <FormGroup>
                         <Input
                              type="email"
                              id="email"
                              name="email"
                              value={formData.email}
                              placeholder='Adresse email'
                              onChange={handleChange}
                              required
                         />
                    </FormGroup>
                    <FormGroup>
                         <Textarea
                              id="message"
                              name="message"
                              value={formData.message}
                              placeholder='Votre message...'
                              onChange={handleChange}
                              rows="6"
                              required
                         />
                    </FormGroup>
                    <RgpdCheckbox>
                         <input
                              type="checkbox"
                              id="rgpd"
                              name="rgpd"
                              checked={isRgpdChecked}
                              onChange={handleCheckboxChange}
                              required
                         />
                         <label htmlFor="rgpd">
                         J'accepte que mes informations soient envoyées au propriétaire de ce site.
                         </label>
                    </RgpdCheckbox>
                    <ButtonStyle type='submit'>Envoyer</ButtonStyle>
               </form>
          </FormContainer>
     );
};

export default ContactForm;