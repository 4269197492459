export const theme = {
    colors: {
          primary: '#CCA43B',
          secondary: '#242F40',
          text: '#242F40',
        },
        fonts: {
          main: 'Montserrat, sans-serif',
          code: 'Mr Dafoe, cursive',
        },
        spacing: {
          small: '12px',
          medium: '18px',
          large: '25px',
        },
}