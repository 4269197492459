import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const ButtonStyle = styled.button`
     background-color: ${(props) => props.bgColor || props.theme.colors.primary};
     color: ${(props) => props.color || props.theme.colors.secondary};
     padding: ${(props) => props.theme.spacing.small} ${(props) => props.theme.spacing.medium};
     border: none;
     font-weight: bold;
     font-size: 16px;
     text-transform: uppercase;
     cursor: pointer;

     &:hover {
          background-color: ${(props) =>
               props.bgColor === props.theme.colors.primary ? props.theme.colors.secondary : props.bgColor};
          color: ${(props) =>
               props.color === props.theme.colors.secondary ? props.theme.colors.primary : props.color};
     }
`;

const CallAction = ({ to, textButton, type = 'button', bgColor, color }) => {
     const navigate = useNavigate();

     const handleClick = () => {
          // Vérifie si le lien commence par 'http' ou 'https' (externe)
          if (to.startsWith('http')) {
               // Ouvre dans un nouvel onglet
               window.open(to, '_blank');
          } else {
               // Sinon, navigation interne via React Router
               navigate(to);
          }
     };

     return (
          <ButtonStyle type={type} onClick={handleClick} bgColor={bgColor} color={color}>
               {textButton}
          </ButtonStyle>
     );
};

export default CallAction;
