import React from "react";
import ReactDOM from "react-dom/client";
import styled, { ThemeProvider } from "styled-components";
import { theme } from "./utils/style/theme";
import GlobalStyle from "./utils/style/GlobaleStyle";
import Home from "./pages/Home";
import Career from "./pages/Career";
import Contact from "./pages/Contact";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Sidebar from "./components/Sidebar/Sidebar";
import SliderProject from "./pages/SliderProject";

const Container = styled.div`
    background-color: ${(props) => props.theme.colors.background};
    display: flex;
`;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <Router>
                <GlobalStyle />
                <Container>
                    <Sidebar />

                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/career" element={<Career />} />
                        <Route path="/project" element={<SliderProject />} />
                        <Route path="/contact" element={<Contact />} />
                    </Routes>
                </Container>
            </Router>
        </ThemeProvider>
    </React.StrictMode>
);