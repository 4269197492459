import styled from "styled-components";
import CallAction from '../../components/Button/CallAction'

const Container = styled.div`
    color: ${(props) => props.theme.colors.secondary};

    margin-left: 200px;
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;

    @media (max-width: 768px) {
        margin-left: 0;
        width: 100%;
        height: 100%;
    }
`

const MiseEnPage = styled.div `
    width: 50%;

    @media (max-width: 768px) {
        width: 80%;
        margin-bottom: 35%;
    }

    
    @media (min-width: 1800px) {
        height: 95%;
    }
`

const ContainerTitle = styled.div `
    width: 135%; 
    margin-top: 12%;

    @media (max-width: 768px) {
        width: 100%;
        margin-top: 4%;
    }

    @media (min-width: 1800px) {
        margin-top: 8%;
    }
`

const Rectangle = styled.div`
    background-color: ${(props) => props.theme.colors.primary};
    width: 550px;
    height: 250px;
    position: absolute;
    right: 0;
    top: 8%;
    z-index: -50;

    @media (max-width: 768px) {
        width: 65%;
        height: 20%;
        top: 5%;
    }
`

const Title = styled.h1`
    text-align: center;
    font-size: 80px;
    margin-left: 22%;
    font-weight: 900;

    @media (max-width: 768px) {
        font-size: 40px;
        margin-left: 0;
        padding-right: 7px;
        margin-top: 20%;
    }
`

const Portfolio = styled.span`
    font-family: ${(props) => props.theme.fonts.code};
    font-weight: 400;
    font-size: 85px;

    @media (max-width: 768px) {
        font-size: 40px;
        margin-left: 5px;
    }
`

const OnMy = styled.span`
    font-weight: 600;
    font-size: 40px;
    margin-left: 22%;

    @media (max-width: 768px) {
        font-size: 20px;
        margin-left: 0;
        padding-left: 80px;
    }
`

const BtnDiv = styled.div`
    display: flex;
    gap: 50px;
    margin-top: 5%;

    @media (max-width: 768px) {
        gap: 20px;
        flex-direction: column;
    }
`

const ContainerPCA = styled.div `
    margin-bottom: 8%;
    margin-top: 12%;

    @media (max-width: 768px) {
        margin-top: 15%;
        p {
            font-size: 14px;
        }
    }

    @media (min-width: 1800px) {
        margin-top: 18%;
    }
`

const Home = () => {
    return (
        <Container>
            <Rectangle />
            <MiseEnPage>
                <ContainerTitle>
                    <Title>
                        Bienvenue
                        <OnMy> <br />sur mon </OnMy>
                        <Portfolio>Portfolio</Portfolio>
                    </Title>
                </ContainerTitle>
                <ContainerPCA>
                    <p>
                        Développeuse web full stack passionnée, capable de transformer des idées en applications fonctionnelles et élégantes. <br /> <br />
                        Sur ce site, vous pourrez découvrir mon parcours, en apprendre plus sur qui je suis et explorer les projets sur lesquels j'ai travaillé. 
                        Vous trouverez également un accès direct à mes réseaux sociaux pour suivre mon activité et rester en contact. <br /> <br />
                        Si vous avez des questions, des idées ou souhaitez collaborer, n'hésitez pas à me contacter. Je suis toujours ouverte à de nouvelles opportunités et défis ! <br /> <br />
                    </p>
                    <BtnDiv>
                        <CallAction textButton='Mon parcours' to='/career' />
                        <CallAction textButton='Mes projets' to='/project' />
                    </BtnDiv>
                </ContainerPCA>
                
            </MiseEnPage>
        </Container>
    )
}

export default Home;

