import styled from "styled-components";
import Photo from '../../assets/img/KimberleyB.jpg';
import CallAction from '../../components/Button/CallAction';

const AboutContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    width: 90%;
    margin-left: 350px;
    background-color: ${(props) => props.theme.colors.background};

    @media (max-width: 768px) {
        justify-content: center;
        width: 100%;
        margin-left: 0;
        height: auto;
        margin-bottom: 30%;
    }

    @media (min-width: 1800px) {
        margin-left: 25%;
    }
`

const TextContainer = styled.div`
    flex: 1;
    padding-right: 250px;

    p {
        font-size: 18px;
        line-height: 1.6;
        margin-bottom: 30px;
    }

    @media (max-width: 768px) {
        flex: initial;
        width: 80%;
        padding-right: 0;
        margin-top: 40px;

        p {
            font-size: 14px;
        }
    }
`

const ImageContainer = styled.div`
    flex: 1;
    position: relative;

    @media (max-width: 768px) {
        display: none;
    }
`

const DecorativeRectangle = styled.div`
    position: absolute;
    width: 500px;
    height: 250px;
    background-color: ${(props) => props.theme.colors.primary};
    top: 30%;
    right: 0;
    z-index: -1;

    @media (max-width: 768px) {
        display: none;
    }

    @media (min-width: 1800px) {
        width: 650px;
        height: 350px;
    }
`

const Image = styled.img`
    width: 300px;
    height: auto;
    object-fit: cover;

    @media (min-width: 1800px) {
        width: 350px;
    }
`

const BtnDiv = styled.div`
    display: flex;
    gap: 50px;
    margin-top: 20px;

    @media (max-width: 768px) {
        gap: 20px;
        flex-direction: column;
        justify-content: center;
    }
`

const About = () => {
    return (
        <AboutContainer>
            <DecorativeRectangle />
            <TextContainer>
                <p>
                    Kimberley Bordier, 26ans.
                </p>
                <p>
                    Passionnée par le développement web, j’ai acquis des compétences et des
                    connaissances en autodidacte, complétées par deux formations professionnelles.
                </p>
                <p>
                    J’ai obtenu le Titre Professionnel Développeur Web et Web Mobile de niveau 5
                    (BAC+2) ainsi que le Titre Professionnel Concepteur Designer UI de niveau 6 (BAC+3).
                </p>
                <p>
                    Ces formations m’ont permis de me familiariser avec la gestion de projet, la création
                    de sites web (front-end et back-end), l’optimisation, le référencement et le web design.
                </p>
                <BtnDiv>
                    <CallAction to="/project" textButton="Mes projets" />
                    <CallAction to="https://drive.google.com/file/d/1EYX52EKjaITeygEh2fPcAs9-fuhZIeA5/view?usp=drive_link" textButton="Mon CV" />
                </BtnDiv>
            </TextContainer>

            <ImageContainer>
                <Image src={Photo} alt="Kimberley Bordier" />
            </ImageContainer>
        </AboutContainer>
    )
}

export default About
