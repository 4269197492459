import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Logo from '../../assets/img/LogoKB.png'
import HomeIcon from "../../assets/icons/sidebar/home.png";
import CareerIcon from "../../assets/icons/sidebar/career.png";
import ProjectsIcon from "../../assets/icons/sidebar/project.png";
import ContactIcon from "../../assets/icons/sidebar/contact.png";

const NavContainer = styled.nav`
     position: fixed;
     width: 200px;
     height: 100vh;

     display: flex;
     flex-direction: column;
     align-items: center;
     background-color: ${(props) => props.theme.colors.secondary};

     @media (max-width: 768px) {
          height: 15%;
          width: 100%;
          bottom: 0;

          flex-direction: row;
          justify-content: center;
          align-content: center;
     }
`

const NavList = styled.ul`
     list-style: none;
     padding: 0;
     margin: 0;

     @media (max-width: 768px) {
          display: flex;
          gap: 20px;
     }

`

const NavItem = styled(NavLink).attrs(({ isActive }) => ({
     className: isActive ? "active" : ""
}))`
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     margin: ${(props) => props.theme.spacing.small};
     padding: 10px;

     text-decoration: none;
     color: ${(props) => props.theme.colors.primary};
     cursor: pointer;
     transition: background-color 0.3s ease;

     &:hover {
          background-color: #34495E;
     }

     &:first-child {
          margin-top: 60%;
     }

     &.active {
          background-color: #2C3E50;
     }

     @media (max-width: 768px) {
          padding: 5px;
          margin: 0;

          &:first-child {
          margin-top: 0;
          }    
     }
`;

const NavIcon = styled.img`
     width: 28px;
     margin-bottom: 5px;

     @media (max-width: 768px) {
          width: 24px;
     }
`

const NavTitle = styled.span`
     padding: 5px;
     font-size: 16px;
     font-weight: 600;

     @media (max-width: 768px) {
          font-size: 14px;
     }
`

const LogoImg = styled.img`
     @media (max-width: 768px) {
          display: none
     }
`


const Sidebar = () => {
     return (
          <NavContainer>
               <LogoImg src={Logo} alt="logo kimberley bordier" width="180px"/>
               <NavList>
               <NavItem to="/">
                    <NavIcon src={HomeIcon} alt="icon home" />
                    <NavTitle>Accueil</NavTitle>
               </NavItem>
               <NavItem to="/career">
                    <NavIcon src={CareerIcon} alt="icon career" />
                    <NavTitle>Parcours</NavTitle>
               </NavItem>
               <NavItem to="/project">
                    <NavIcon src={ProjectsIcon} alt="icon project" />
                    <NavTitle>Projets</NavTitle>
               </NavItem>
               <NavItem to="/contact">
                    <NavIcon src={ContactIcon} alt="icon contact" />
                    <NavTitle>Contact</NavTitle>
               </NavItem>
               </NavList>
          </NavContainer>

     )
}


export default Sidebar