import styled from "styled-components";
import ContactForm from "../../components/Form/ContactForm";
import LinkedInIcon from '../../assets/icons/social-media/linkedin.png';
import GitHubIcon from '../../assets/icons/social-media/github.png';

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-left: 250px;

    @media (max-width: 768px) {
        height: 100%;
        width: 100%;
        margin-bottom: 40%;
        margin-top: 20px;
        margin-left: 0;
    }
`;

const ContainerForm = styled.div`
    align-self: flex-start;
    margin-top: auto;
    
    @media (max-width: 768px) {
        margin-top: 15%;
        width: 100%;
    }
`;

const ContactDetails = styled.div`
    color: ${(props) => props.theme.colors.primary};
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: flex-end;

    height: 100%;
    width: 100%;
    gap: 112px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 0;
        margin-top: 5%;
        padding-right: 65px;
    }

    @media (min-width: 1800px) {
        height: 30%;
    }
`;

const Rectangle = styled.div`
    background-color: ${(props) => props.theme.colors.secondary};
    width: 550px;
    height: 280px;
    position: absolute;
    right: 0;
    top: 8%;
    z-index: -50;

    @media (max-width: 768px) {
        top: 5%;
        width: 70%;
        height: 30%;
    }
`;

const Title = styled.h1`
    font-size: 52px;
    font-weight: bold;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        font-size: 32px;
        margin-left: 0; 
        margin-top: 5%;
        padding-right: 0; 
    }
`;

const LastName = styled.span`
    font-family: ${(props) => props.theme.fonts.code};
    font-weight: 400;
    font-size: 68px;
    margin-left: 45%;

    @media (max-width: 768px) {
        font-size: 32px;
        margin-left: 45%;
    }
`;

const ContactInfo = styled.div`
    // margin-top: 25%;
    margin-right: 25px;
    p {
        margin: 0;
        line-height: 1.6;
    }

    @media (max-width: 768px) {
        margin-top: 5%;
        margin-right: 0;
        margin-left: 10%;

        p {
            font-size: 14px;
        }
    }
`;

const SocialMedia = styled.div`
    margin-top: 10%;
    display: flex;
    gap: 20px;
    img {
        width: 32px;
        height: 32px;
    }

    @media (max-width: 768px) {
        margin-top: 15px;

        img {
            width: 24px;
            height: 24px;
        }
    }
`;

const MiseEnPage = styled.div `
    // width: 80%;

    @media (max-width: 768px) {
        width: 80%;
        margin-left: -30%;
    }
`

const Contact = () => {
    return (
        <Container>
            <Rectangle />
            <ContainerForm>
                <ContactForm />
            </ContainerForm>
            <ContactDetails>
                <MiseEnPage>
                    <Title>
                        Kimberley
                        <LastName>Bordier</LastName>
                    </Title>
                </MiseEnPage>
                
                <ContactInfo>
                    <div>
                        <p>06 67 91 02 69</p>
                        <p>kimberleybordierr@gmail.com</p>
                    </div>
                    <SocialMedia>
                        <a href="https://www.linkedin.com/in/kimberley-bordier/" target="_blank" rel="noopener noreferrer">
                            <img src={LinkedInIcon} alt="LinkedIn icon" />
                        </a>
                        <a href="https://github.com/KimberleyBdr" target="_blank" rel="noopener noreferrer">
                            <img src={GitHubIcon} alt="GitHub icon" />
                        </a>
                    </SocialMedia>
                </ContactInfo>
            </ContactDetails>
        </Container>
    );
};

export default Contact;