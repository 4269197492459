import styled from 'styled-components';

const StackContainer = styled.div`
    position: absolute;
    left: 55%;
    top: 32%;
    display: inline-block;
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.secondary};
    padding: ${(props) => props.theme.spacing.small} 30px;

    @media (max-width: 768px) {
        position: relative;
        left: 0%;
        top: 3%;
        padding: ${(props) => props.theme.spacing.small} 30px;
    }
`;

const TitleDiv = styled.h3`
    font-weight: black;
    font-size: 22px;
    padding: ${(props) => props.theme.spacing.large} 0;

    @media (max-width: 768px) {
        font-size: 16px;
        padding: 5% 0;
    }
`;

const ImageContainer = styled.div`
    display: flex;
    gap: 35px;
    margin: 0 -4px;
    padding: ${(props) => props.theme.spacing.large} 0;

    @media (max-width: 768px) {
        gap: 20px;
        padding: 5% 0;
    }
`;

const Image = styled.img`
    width: 40px;
    height: 40px;

    @media (max-width: 768px) {
        width: 24px;
        height: 24px;
    }
`;

const StackUsed = ({ images }) => {
  // Assure-toi que images est défini et est un tableau avant d'utiliser map
  if (!images || !Array.isArray(images)) {
    return null; // Ou afficher un message de fallback ou de chargement
  }

  return (
    <StackContainer>
      <TitleDiv>Langages utilisé</TitleDiv>
      <ImageContainer>
        {images.map((img, index) => (
          img ? <Image key={index} src={img} alt={`image-${index}`} /> : null
        ))}
      </ImageContainer>
    </StackContainer>
  );
};

export default StackUsed;